<template>
  <div class="mobile-home">
    <div class="home-banner">
      <div class="home-banner-text">
        <span class="get-solution-btn" @click="getSolution">
          <span class="get-solution-btn-text"></span>
        </span>
      </div>
    </div>
    <div class="house-wrap">
      <div class="house-title">我们的智能家居</div>
      <div class="house-houseControl">
        <div class="house-houseControl-text">
          <h5>全屋控制解决方案</h5>
          <h6>Whole House Control Solutions</h6>
          <p>您在回家的路上就可以打开家中的空调、热水器、灯具、音乐等，等回到家中便可从容地享受舒适生活。一木的家电控制系统使用户无论在何时何地都可以进行全屋的家电操控，并且拥有智能安防报警功能，防火防盗，防燃气泄漏。智能、安全保障您的生活。</p>
          <div class="house-houseControl-img">

          </div>
        </div>
      </div>
    </div>
    <div class="industry-wrap">
      <div class="industry-title">我们的工业智能</div>
      <div class="industry-detectingSystem">
        <div class="industry-detectingSystem-text">
          <h5>生产状态智能监测系统</h5>
          <h6>DETECTING SYSTEM</h6>
          <p>本系统是基于机器学习的生产状态监测系统，产品适用于三相电机驱动的加工设备，能够对加工过程的设备、制品及人员状态进行实时监测。系统支持为云系统或者工业系统提供数据，为未导入制造执行系统的工厂提供轻量数字化服务，帮助工厂实现加工现场的快速智能化升级。</p>
          <div class="industry-detectingSystem-img">

          </div>
        </div>
      </div>
      <div class="industry-automaticallyGrabsSystem">
        <div class="industry-automaticallyGrabsSystem-text">
          <h5>智能上下料系统</h5>
          <h6>AUTOMATICALLY GRABS SYSTEM</h6>
          <p>机器视觉广泛的应用于工业自动化、机器人、质量检测、产品追溯中，俗称工业的眼睛。本系统是利用摄像机、镜头、光源和智能检测算法来人眼在工业中实现缺陷检测、尺寸测量、机器人引导定位和文字识别等功能。</p>
          <div class="industry-automaticallyGrabsSystem-img">

          </div>
        </div>
      </div>
      <div class="cooperation-wrap">
        <div class="cooperation-main">
          <div class="cooperation-title">合作案例</div>
          <div class="cooperation-state">众多企业选择与一木专业团队合作</div>
          <div class="cooperative-enterprise-list">
            <img src="~/assets/img/home/cooperative_enterprise_01.png" width="289px" height="114px" alt="">
            <img src="~/assets/img/home/cooperative_enterprise_02.png" width="289px" height="114px" alt="">
            <img src="~/assets/img/home/cooperative_enterprise_03.png" width="289px" height="114px" alt="">
            <img src="~/assets/img/home/cooperative_enterprise_04.png" width="289px" height="114px" alt="">
          </div>
        </div>
      </div>
      <div class="exclusive-solution-wrap">
        <div class="exclusive-solution-main">
          <div class="exclusive-solution-title">定制您的专属解决方案</div>
          <div class="get-exclusive-solution-btn" @click="getSolution">立即获取</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'MobileHome',
  layout: 'mobile',
  data() {
    return {
      newsTab: 1
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    switchNewsTab(tab) {
      this.newsTab = tab
    },
    getSolution() {
      this.$router.push({ path: '/mobile/solution', query: { next: '/mobile'}})
    },
    ...mapMutations([

    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([

    ])
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
.mobile-home {
  
}
.home-banner {
  height: 11.5rem;
  background: url('~assets/img/mobile/home/banner.png') no-repeat;
  background-size: cover;
  position: relative;
}
.home-banner-text {
  width: 100%;
  height: 100%;
  background: url('~assets/img/mobile/home/banner_text_01.png') no-repeat;
  background-size: cover;
  position: relative;
}
.get-solution-btn {
  width: 3.55rem;
  height: 1rem;
  background: url('~assets/img/mobile/home/button_bg.png') no-repeat;
  background-size: cover;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5.9rem;
}
.house-wrap {
  background: #fff;
  padding-top: 1.65rem;
  .house-title {
    font-family: PingFang SC;
    font-weight: bold;
    color: #083157;
    @include fh(1.2rem);
    text-align: center;
  }
  .house-houseControl {
    margin-top: 1.65rem;
    padding: 0 1.3rem;
    .house-houseControl-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      >h5 {
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
        @include fh(1rem);
        text-align: center;
      }
      >h6 {
        margin-top: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
        @include fh(0.75rem);
        text-align: center;
        opacity: 0.8;
      }
      >p {
        margin-top: 1.65rem;
        font-size: 0.45rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F4E6D;
        line-height: 0.75rem;
        font-size: 0.45rem;
        opacity: 0.6;
      }
      .house-houseControl-img {
        margin-top: 1.65rem;
        width: 15rem;
        height: 8.6rem;
        border-radius: 0.3rem;
        background: url('~assets/img/home/houseControl_img1.png') no-repeat;
        background-position: center;
        background-size: 100%;
      }
    }
  }
}
.industry-wrap {
  margin-top: 1.65rem;
  background: #fff;
  padding-top: 1.65rem;
  .industry-title {
    font-family: PingFang SC;
    font-weight: bold;
    color: #083157;
    @include fh(1.2rem);
    text-align: center;
  }
  .industry-detectingSystem {
    margin-top: 1.65rem;
    padding: 0 1.3rem;
    .industry-detectingSystem-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      >h5 {
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
        @include fh(1rem);
        text-align: center;
      }
      >h6 {
        margin-top: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
        @include fh(0.75rem);
        text-align: center;
        opacity: 0.8;
      }
      >p {
        margin-top: 1.65rem;
        font-size: 0.45rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F4E6D;
        line-height: 0.75rem;
        font-size: 0.45rem;
        opacity: 0.6;
      }
      .industry-detectingSystem-img {
        margin-top: 1.65rem;
        width: 15rem;
        height: 8.6rem;
        border-radius: 0.3rem;
        background: url('~assets/img/home/detectingSystem_img1.png') no-repeat;
        background-position: center;
        background-size: 100%;
      }
    }
  }
  .industry-automaticallyGrabsSystem {
    margin-top: 1.65rem;
    padding: 0 1.3rem;
    .industry-automaticallyGrabsSystem-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      >h5 {
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
        @include fh(1rem);
        text-align: center;
      }
      >h6 {
        margin-top: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2F4E6D;
        @include fh(0.75rem);
        text-align: center;
        opacity: 0.8;
      }
      >p {
        margin-top: 1.65rem;
        font-size: 0.45rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F4E6D;
        line-height: 0.75rem;
        font-size: 0.45rem;
        opacity: 0.6;
      }
      .industry-automaticallyGrabsSystem-img {
        margin-top: 1.65rem;
        width: 15rem;
        height: 8.6rem;
        border-radius: 0.3rem;
        background: url('~assets/img/home/automaticallyGrabsSystem_img1.png') no-repeat;
        background-position: center;
        background-size: 100%;
      }
    }
  }
}
.cooperation-wrap {
  // height: 376px;
  margin-top: 2.5rem;
  background: #F8FBFE;
}
.cooperation-main {
  // width: 1200px;
  margin: auto;
  padding-top: 1.1rem;
}
.cooperation-title {
  @include fh(0.6rem);
  color: #000;
  text-align: center;
}
.cooperation-state {
  font-size: 0.5rem;
  color: rgba(0, 0, 0, .4);
  margin-top: 0.75rem;
  text-align: center;
}
.cooperative-enterprise-list {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  img {
    width: 3.9rem;
    height: 1.55rem;
    margin-right: 0.15rem;
  }
}
.exclusive-solution-wrap {
  background: url('~assets/img/home/exclusive_solution_bg.png') no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.exclusive-solution-main {
  padding: 0.55rem 0;
  // width: 1200px;
  margin: 0.45rem auto 0;
}
.exclusive-solution-title {
  font-size: 0.55rem;
  text-align: center;
  color: #F6F6F6;
}
.get-exclusive-solution-btn {
  width: 3rem;
  height: 0.85rem;
  line-height: 0.85rem;
  text-align: center;
  border-radius: 0.45rem;
  background: #fff;
  color: #0B70F6;
  font-size: 0.4rem;
  margin: 0.55rem auto 0;
  cursor: pointer;
}
</style>
